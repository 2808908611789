.toast-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #333;
    color: #71f06d;
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1000;
  }
  
  .toast-message {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .toast-close-button {
    background: none;
    border: none;
    color: hsl(121, 87%, 47%);
    font-size: 20px;
    cursor: pointer;
    margin-left: 10px;
  }
  .Toastify {
    position: absolute;
    z-index: 999999 !important;
}
  