.not-found-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .not-found-content {
    text-align: center;
  }
  
  .not-found-icon {
    font-size: 3rem;
    margin-bottom: 20px;
  }
  
  .not-found-title {
    font-size: 2.5rem;
    margin-bottom: 10px;
  }
  
  .not-found-text {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
  
  .not-found-link {
    text-decoration: none;
    color: #007bff;
    font-size: 1.2rem;
    transition: all 0.3s ease;
  }
  
  .not-found-link:hover {
    color: #0056b3;
  }
  