:root {
    scroll-behavior: smooth;
}

body {
    font-family: "Open Sans", sans-serif;
    background: rgba(239, 238, 247, 255);
    background-color: #f0eff7 !important;
    color: #444444;
}

a {
    color: #4154f1;
    text-decoration: none;
}

a:hover {
    color: #717ff5;
    text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Nunito", sans-serif;
}

/*--------------------------------------------------------------
  # Main
  --------------------------------------------------------------*/
#main {
    padding: 20px;
    transition: background-color 0.3s;
    background-color: white;
    /* Default background color */
    margin-top: 20px;
    margin-bottom: 20px;
    height: 800px;
    position: relative;
}

/* Overlay that covers the entire main section */
.loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    width: 50px;

}

#main.loading {
    background-color: transparent !important;
}

.large-spinner {
    width: 200px !important;
    height: 50px !important;
}



.main-wrap {}



@media (max-width: 1199px) {
    #main {
        padding: 20px;
    }
}



.custom-input {
    width: 100%;
    height: 36px;
    padding: 10px 10px 10px 40px;
    /* Padding for the search icon inside */
    border: 2px solid #f0eff7;
    background-color: #f0eff7 !important;
    /* Sharp border */
    border-radius: 0;
    /* No border-radius for sharp corners */
    outline: none;
    transition: border-color 0.3s ease;
    background: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"%3E%3Cpath fill="none" stroke="%23000" stroke-width="2" d="M21 21l-6-6m0 0a7 7 0 1 1 1-1 7 7 0 0 1-1 1z"/%3E%3C/svg%3E') no-repeat 10px center;
    /* Search icon inside placeholder */
    background-size: 20px 20px;
    background: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"%3E%3Cpath fill="none" stroke="%23000" stroke-width="2" d="M21 21l-6-6m0 0a7 7 0 1 1 1-1 7 7 0 0 1-1 1z"/%3E%3C/svg%3E') no-repeat 10px center;
    /* Search icon inside placeholder */
    background-size: 20px 20px;
    background: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"%3E%3Cpath fill="none" stroke="%23000" stroke-width="2" d="M21 21l-6-6m0 0a7 7 0 1 1 1-1 7 7 0 0 1-1 1z"/%3E%3C/svg%3E') no-repeat 10px center;
    /* Search icon inside placeholder */
    background-size: 20px 20px;
    background: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"%3E%3Cpath fill="none" stroke="%23000" stroke-width="2" d="M21 21l-6-6m0 0a7 7 0 1 1 1-1 7 7 0 0 1-1 1z"/%3E%3C/svg%3E') no-repeat 10px center;
    /* Search icon inside placeholder */
    background-size: 20px 20px;
    background: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"%3E%3Cpath fill="none" stroke="blue" stroke-width="2" d="M21 21l-6-6m0 0a7 7 0 1 1 1-1 7 7 0 0 1-1 1z"/%3E%3C/svg%3E') no-repeat 10px center;
    /* Search icon inside placeholder */
    background-size: 20px 20px;
}

.custom-input::placeholder {
    color: #999;
}


/*--------------------------------------------------------------
  # Page Title
  --------------------------------------------------------------*/
.pagetitle {
    display: flex;
    justify-content: space-between;
    align-items: center !important;
    text-align: center !important;
    height: 20px;
    padding: 15px;

}

.pagetitle h1 {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center !important;
    text-align: center !important;
    font-size: 25px;
    margin-bottom: 0;
    font-weight: 600;
    color: #1048a8;
}

.searchButtonCorner {
    display: flex;
    justify-content: space-between;
    width: 1034px;

}

/*--------------------------------------------------------------
  # Back to top button
  --------------------------------------------------------------*/
.back-to-top {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    right: 15px;
    bottom: 15px;
    z-index: 99999;
    background: #4154f1;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    transition: all 0.4s;
}

.back-to-top i {
    font-size: 24px;
    color: #fff;
    line-height: 0;
}

.back-to-top:hover {
    background: #6776f4;
    color: #fff;
}

.back-to-top.active {
    visibility: visible;
    opacity: 1;
}

/*--------------------------------------------------------------
  # Override some default Bootstrap stylings
  --------------------------------------------------------------*/
/* Dropdown menus */
.dropdown-menu {
    border-radius: 4px;
    padding: 10px 0;
    animation-name: dropdown-animate;
    animation-duration: 0.2s;
    animation-fill-mode: both;
    border: 0;
    box-shadow: 0 5px 30px 0 rgba(82, 63, 105, 0.2);
}

.dropdown-menu .dropdown-header,
.dropdown-menu .dropdown-footer {
    text-align: center;
    font-size: 15px;
    padding: 10px 25px;
}

.dropdown-menu .dropdown-footer a {
    color: #444444;
    text-decoration: underline;
}

.dropdown-menu .dropdown-footer a:hover {
    text-decoration: none;
}

.dropdown-menu .dropdown-divider {
    color: #a5c5fe;
    margin: 0;
}

.dropdown-menu .dropdown-item {
    font-size: 14px;
    padding: 10px 15px;
    transition: 0.3s;
}

.dropdown-menu .dropdown-item i {
    margin-right: 10px;
    font-size: 18px;
    line-height: 0;
}

.dropdown-menu .dropdown-item:hover {
    background-color: #f6f9ff;
}

@media (min-width: 768px) {
    .dropdown-menu-arrow::before {
        content: "";
        width: 13px;
        height: 13px;
        background: #fff;
        position: absolute;
        top: -7px;
        right: 20px;
        transform: rotate(45deg);
        border-top: 1px solid #eaedf1;
        border-left: 1px solid #eaedf1;
    }
}

@keyframes dropdown-animate {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }

    0% {
        opacity: 0;
    }
}

/* Light Backgrounds */
.bg-primary-light {
    background-color: #cfe2ff;
    border-color: #cfe2ff;
}

.bg-secondary-light {
    background-color: #e2e3e5;
    border-color: #e2e3e5;
}

.bg-success-light {
    background-color: #d1e7dd;
    border-color: #d1e7dd;
}

.bg-danger-light {
    background-color: #f8d7da;
    border-color: #f8d7da;
}

.bg-warning-light {
    background-color: #fff3cd;
    border-color: #fff3cd;
}

.bg-info-light {
    background-color: #cff4fc;
    border-color: #cff4fc;
}

.bg-dark-light {
    background-color: #d3d3d4;
    border-color: #d3d3d4;
}

/* Card */
.card {
    width: 1400px !important;
    margin-bottom: 30px;
    margin-left: 0px;
    border: none;
    border-radius: 5px;
    box-shadow: 0px 0 30px rgba(1, 41, 112, 0.1);
    border: 2px solid rgb(255, 255, 255) !important;

}

.card-header,
.card-footer {
    border-color: #ebeef4;
    background-color: #fff;
    color: #798eb3;
    padding: 15px;
}

.card-title {
    padding: 20px 0 15px 0;
    font-size: 18px;
    font-weight: 500;
    color: #012970;
    font-family: "Poppins", sans-serif;
}

.card-title span {
    color: #899bbd;
    font-size: 14px;
    font-weight: 400;
}

.card-body {
    padding: 0 20px 20px 20px;
}

.card-img-overlay {
    background-color: rgba(255, 255, 255, 0.6);
}

/* Alerts */
.alert-heading {
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    font-size: 20px;
}

/* Close Button */
.btn-close {
    background-size: 25%;
}

.btn-close:focus {
    outline: 0;
    box-shadow: none;
}

/* Accordion */
.accordion-item {
    border: 1px solid #ebeef4;
}

.accordion-button:focus {
    outline: 0;
    box-shadow: none;
}

.accordion-button:not(.collapsed) {
    color: #012970;
    background-color: #f6f9ff;
}

.accordion-flush .accordion-button {
    padding: 15px 0;
    background: none;
    border: 0;
}

.accordion-flush .accordion-button:not(.collapsed) {
    box-shadow: none;
    color: #4154f1;
}

.accordion-flush .accordion-body {
    padding: 0 0 15px 0;
    color: #3e4f6f;
    font-size: 15px;
}

/* Breadcrumbs */
.breadcrumb {
    margin-top: 10px;
    font-family: "Nunito", sans-serif;
    color: #899bbd;
    font-weight: 700;

}

.breadcrumb-item {
    font-size: 12px;
}

.breadcrumb a {
    color: #899bbd;
    transition: 0.3s;
}

.breadcrumb a:hover {
    color: #51678f;
}

.breadcrumb .breadcrumb-item::before {
    color: #899bbd;
}

.breadcrumb .active {
    color: #51678f;
    font-weight: 600;
}

/* Bordered Tabs */
.nav-tabs-bordered {
    border-bottom: 2px solid #ebeef4;
}

.nav-tabs-bordered .nav-link {
    margin-bottom: -2px;
    border: none;
    color: #2c384e;
}

.nav-tabs-bordered .nav-link:hover,
.nav-tabs-bordered .nav-link:focus {
    color: #4154f1;
}

.nav-tabs-bordered .nav-link.active {
    background-color: #fff;
    color: #4154f1;
    border-bottom: 2px solid #4154f1;
}

/*--------------------------------------------------------------
  # Header
  --------------------------------------------------------------*/
.logo {
    line-height: 1;
}

@media (min-width: 1200px) {
    .logo {
        width: 280px;
    }
}

.logo img {
    max-height: 26px;
    margin-right: 6px;
}

.logo span {
    font-size: 26px;
    font-weight: 700;
    color: #012970;
    font-family: "Nunito", sans-serif;
}

/* .header {
    transition: all 0.5s;
    height: 55px;
    box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
    background-color: #ffffff;
    margin-top: 20px;
    margin-left: 17%;
    margin-right: 50%;
    width: 1500px;
    /* Toggle Sidebar Button */
/* Search Bar */




.header {
    transition: all 0.5s;
    height: 55px;
    box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
    background-color: #ffffff;
    margin-left: 17%;
    width: 1530px;

}

.name {
    font-size: 15px;
}

.pageTitleNme {
    margin-left: 300px !important;
    margin-top: 80px;
    width: 1500px !important;
    display: flex;
    justify-content: space-between;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
    .header {
        margin-left: 10%;
        margin-right: 10%;
        width: auto;
    }
}

@media (max-width: 992px) {
    .header {
        margin-left: 5%;
        margin-right: 5%;
        width: auto;
    }
}

@media (max-width: 768px) {
    .header {
        margin-left: 0;
        margin-right: 0;
        width: 100%;
    }
}

@media (max-width: 576px) {
    .header {
        height: auto;
        /* Adjust height if needed for very small screens */
        padding: 10px;
        box-sizing: border-box;
    }
}


.header .toggle-sidebar-btn {
    font-size: 32px;
    padding-left: 10px;
    cursor: pointer;
    color: #012970;
}

.search-bar {

    width: 220px;
}

@media (max-width: 1199px) {
    .header .search-bar {
        position: fixed;
        top: 50px;
        left: 0;
        right: 0;
        padding: 20px;
        box-shadow: 0px 0px 15px 0px rgba(1, 41, 112, 0.1);
        background: #ffffff;
        z-index: 99;
        transition: 0.3s;
        visibility: hidden;
        opacity: 0;
    }

    .header .search-bar-show {
        top: 60px;
        visibility: visible;
        opacity: 1;
    }
}

.header .search-form {
    width: 100%;
}

.header .search-form input {
    border: 0;
    font-size: 14px;
    color: #012970;
    border: 1px solid rgba(1, 41, 112, 0.2);
    padding: 7px 38px 7px 8px;
    border-radius: 3px;
    transition: 0.3s;
    width: 100%;
}

.header .search-form input:focus,
.header .search-form input:hover {
    outline: none;
    box-shadow: 0 0 10px 0 rgba(1, 41, 112, 0.15);
    border: 1px solid rgba(1, 41, 112, 0.3);
}

.header .search-form button {
    border: 0;
    padding: 0;
    margin-left: -30px;
    background: none;
}

.header .search-form button i {
    color: #012970;
}

/*--------------------------------------------------------------
  # Header Nav
  --------------------------------------------------------------*/
.rounded-circle {
    width: 40px;
}

/* .header-nav ul {
    list-style: none;
}

.header-nav>ul {
    margin: 0;
    padding: 0;
}

.header-nav .nav-icon {
    font-size: 22px;
    color: #012970;
    margin-right: 25px;
    position: relative;
}

.header-nav .nav-profile {
    color: #012970;
}

.header-nav .nav-profile img {
    max-height: 36px;
}

.header-nav .nav-profile span {
    font-size: 14px;
    font-weight: 600;
}

.header-nav .badge-number {
    position: absolute;
    inset: -2px -5px auto auto;
    font-weight: normal;
    font-size: 12px;
    padding: 3px 6px;
}

.header-nav .notifications {
    inset: 8px -15px auto auto !important;
}

.header-nav .notifications .notification-item {
    display: flex;
    align-items: center;
    padding: 15px 10px;
    transition: 0.3s;
}

.header-nav .notifications .notification-item i {
    margin: 0 20px 0 10px;
    font-size: 24px;
}

.header-nav .notifications .notification-item h4 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
}

.header-nav .notifications .notification-item p {
    font-size: 13px;
    margin-bottom: 3px;
    color: #919191;
}

.header-nav .notifications .notification-item:hover {
    background-color: #f6f9ff;
}

.header-nav .messages {
    inset: 8px -15px auto auto !important;
}

.header-nav .messages .message-item {
    padding: 15px 10px;
    transition: 0.3s;
}

.header-nav .messages .message-item a {
    display: flex;
}

.header-nav .messages .message-item img {
    margin: 0 20px 0 10px;
    max-height: 40px;
}

.header-nav .messages .message-item h4 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
    color: #444444;
}

.header-nav .messages .message-item p {
    font-size: 13px;
    margin-bottom: 3px;
    color: #919191;
}

.header-nav .messages .message-item:hover {
    background-color: #2366ec;
}

.header-nav .profile {
    min-width: 240px;
    padding-bottom: 0;
    top: 8px !important;
}

.header-nav .profile .dropdown-header h6 {
    font-size: 18px;
    margin-bottom: 0;
    font-weight: 600;
    color: #444444;
}

.header-nav .profile .dropdown-header span {
    font-size: 14px;
}

.header-nav .profile .dropdown-item {
    font-size: 14px;
    padding: 10px 15px;
    transition: 0.3s;
}

.header-nav .profile .dropdown-item i {
    margin-right: 10px;
    font-size: 18px;
    line-height: 0;
}

.header-nav .profile .dropdown-item:hover {
    background-color: #f6f9ff;
} */

/*--------------------------------------------------------------
  # Sidebar
  --------------------------------------------------------------*/
.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 280px;
    z-index: 998;
    transition: all 0.3s;
    padding: 15px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #aab7cf transparent;
    box-shadow: 0px 0px 20px rgba(1, 41, 112, 0.1);
    background-color: #1467A5;
}

@media (max-width: 1199px) {
    .sidebar {
        left: -300px;
    }
}

.sidebar::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background-color: #881515;
}

.sidebar::-webkit-scrollbar-thumb {
    background-color: #aab7cf;
}

@media (min-width: 1200px) {

    #main,
    #footer {
        margin-left: 300px;
    }
}

@media (max-width: 1199px) {
    .toggle-sidebar .sidebar {
        left: 0;
    }
}

@media (min-width: 1200px) {

    .toggle-sidebar #main,
    .toggle-sidebar #footer {
        margin-left: 0;
    }

    .toggle-sidebar .sidebar {
        left: -300px;
    }
}

.sidebar-nav {
    padding: 0;
    margin: 0;
    list-style: none;

}

.sidebar-nav li {
    padding: 0;
    margin: 0;
    list-style: none;
}

.sidebar-nav .nav-item {
    margin-bottom: 5px;
}

.sidebar-nav .nav-heading {
    font-size: 11px;
    text-transform: uppercase;
    color: #899bbd;
    font-weight: 600;
    /* margin: 10px 0 5px 15px; */
}

.sidebar-nav .nav-link {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    color: #bcb9c7;
    transition: 0.3;
    padding: 12px 15px;
    border-radius: 0px;
    margin-left: 10px;
}

.sidebar-nav .nav-link i {
    font-size: 16px;
    margin-right: 10px;
    color: #e7880a;
}

.sidebar-nav .nav-link.collapsed {
    color: #ffffff;

}

.sidebar-nav .nav-link.collapsed i {
    color: #faca2a
}

.sidebar-nav .nav-link:hover {
    color: #1467A5 !important;
    background-color: #fac92a80;

}

.sidebar-nav .nav-link:hover i {
    color: #1467A5 !important;
}

.sidebar-nav .nav-link .bi-chevron-down {
    margin-right: 0;
    transition: transform 0.2s ease-in-out;
}

.sidebar-nav .nav-link:not(.collapsed) .bi-chevron-down {
    transform: rotate(180deg);
}

.sidebar-nav .nav-content {
    padding: 10px 0 0 0;
    margin: 0;
    /* list-style: none; */
}

.sidebar-nav .nav-content a {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    color: #f0f0f0;
    transition: 0.3;
    padding: 10px 0 10px 40px;
    transition: 0.3s;
}

.sidebar-nav .nav-content a i {
    font-size: 6px;
    margin-right: 8px;
    line-height: 0;
    border-radius: 50%;
}

.sidebar-nav .nav-content a:hover,
.sidebar-nav .nav-content a.active {
    color: #1467A5;
    background-color: #faca2a
}

.sidebar-nav .nav-content a.active i {
    color: #1467A5;
    background-color: #faca2a
}

.nav-link a.active {
    color: #1467A5 !important;
}

/*--------------------------------------------------------------
  # Dashboard
  --------------------------------------------------------------*/
/* Filter dropdown */
.dashboard .filter {
    position: absolute;
    right: 0px;
    top: 15px;
}

.dashboard .filter .icon {
    color: #aab7cf;
    padding-right: 20px;
    padding-bottom: 5px;
    transition: 0.3s;
    font-size: 16px;
}

.dashboard .filter .icon:hover,
.dashboard .filter .icon:focus {
    color: #4154f1;
}

.dashboard .filter .dropdown-header {
    padding: 8px 15px;
}

.dashboard .filter .dropdown-header h6 {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    color: #aab7cf;
    margin-bottom: 0;
    padding: 0;
}

.dashboard .filter .dropdown-item {
    padding: 8px 15px;
}

/* Info Cards */
.dashboard .info-card {
    padding-bottom: 10px;
}

.dashboard .info-card h6 {
    font-size: 28px;
    color: #012970;
    font-weight: 700;
    margin: 0;
    padding: 0;
}

.dashboard .card-icon {
    font-size: 32px;
    line-height: 0;
    width: 64px;
    height: 64px;
    flex-shrink: 0;
    flex-grow: 0;
}

.dashboard .sales-card .card-icon {
    color: #4154f1;
    background: #f6f6fe;
}

.dashboard .revenue-card .card-icon {
    color: #2eca6a;
    background: #e0f8e9;
}

.dashboard .customers-card .card-icon {
    color: #ff771d;
    background: #ffecdf;
}

/* Activity */
.dashboard .activity {
    font-size: 14px;
}

.dashboard .activity .activity-item .activite-label {
    color: #888;
    position: relative;
    flex-shrink: 0;
    flex-grow: 0;
    min-width: 64px;
}

.dashboard .activity .activity-item .activite-label::before {
    content: "";
    position: absolute;
    right: -11px;
    width: 4px;
    top: 0;
    bottom: 0;
    background-color: #eceefe;
}

.dashboard .activity .activity-item .activity-badge {
    margin-top: 3px;
    z-index: 1;
    font-size: 11px;
    line-height: 0;
    border-radius: 50%;
    flex-shrink: 0;
    border: 3px solid #fff;
    flex-grow: 0;
}

.dashboard .activity .activity-item .activity-content {
    padding-left: 10px;
    padding-bottom: 20px;
}

.dashboard .activity .activity-item:first-child .activite-label::before {
    top: 5px;
}

.dashboard .activity .activity-item:last-child .activity-content {
    padding-bottom: 0;
}

/* News & Updates */
.dashboard .news .post-item+.post-item {
    margin-top: 15px;
}

.dashboard .news img {
    width: 80px;
    float: left;
    border-radius: 5px;
}

.dashboard .news h4 {
    font-size: 15px;
    margin-left: 95px;
    font-weight: bold;
    margin-bottom: 5px;
}

.dashboard .news h4 a {
    color: #012970;
    transition: 0.3s;
}

.dashboard .news h4 a:hover {
    color: #4154f1;
}

.dashboard .news p {
    font-size: 14px;
    color: #777777;
    margin-left: 95px;
}

/* tabel */
/* Custom pagination styles */

/* pagination  */
.pagination-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 25px;
}

.pagination {
    display: flex;
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.pagination li {
    margin: 0 2px;
    /* Reduced margin */
}

.pagination a {
    display: block;
    padding: 4px 8px;
    /* Reduced padding */
    border: 1px solid #dee2e6;
    border-radius: 4px;
    color: #007bff !important;
    text-decoration: none;
    font-size: 12px;
    /* Reduced font size */
}

.pagination a:hover {
    background-color: #f0f0f0;
}

.pagination .active a {
    background-color: #007bff;
    color: #fff !important;
    border-color: #007bff;
}

.results-info {
    font-size: 12px;
    /* Reduced font size */
    color: #6c757d;
}


/* Faded text color for specific columns */
.fade-text {
    color: #6c757d;
    /* Faded color */
}

/* Active and inactive status styles */
.active-status {
    border-radius: 2px;
    background-color: #e9f1ef;
    padding: 5px;
}

.inactive-status {
    color: red;
}

/* DataTable Styles */

.react-data-table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    margin: 20px 0;
}

.react-data-table thead th {
    border-bottom: 1px solid #095bad !important;
    /* Column bottom border */
    background-color: #f8f9fa;
    text-align: left;
    padding: 10px;
}

.react-data-table tbody td {
    border-bottom: 1px solid #dee2e6;
    /* Row bottom border */
    padding: 2px !important;

}

.react-data-table tbody tr:last-child td {
    border-bottom: none;
    /* Remove border from the last row */
}

.react-data-table tbody tr:hover {
    background-color: #f1f1f1;
    /* Highlight row on hover */
}

/* Remove all other borders */
.react-data-table thead th,
.react-data-table tbody td {
    border-bottom: 2px dotted rgb(206, 202, 202) !important;
    border-right: none !important;
    border-left: none !important;
    border-top: none !important;
}


/* Recent Sales */
.dashboard .recent-sales {
    font-size: 14px;
}

.dashboard .recent-sales {
    background: #f6f6fe;
}

.dashboard .recent-sales {
    border: 0;
}

.dashboard .recent-sales {
    padding: 0 0 10px 0;
}

.dashboard .recent-sales {
    padding: 10px 0 0 0;
}

/* Top Selling */
.dashboard .top-selling {
    font-size: 14px;
}

.dashboard .top-selling {
    background: #f6f6fe;
}

.dashboard .top-selling {
    border: 0;
}

.dashboard .top-selling {
    vertical-align: middle;
}

.dashboard .top-selling img {
    border-radius: 5px;
    max-width: 60px;
}

/*--------------------------------------------------------------
  # Icons list page
  --------------------------------------------------------------*/
.iconslist {
    display: grid;
    max-width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    gap: 1.25rem;
    padding-top: 15px;
}

.iconslist .icon {
    background-color: #fff;
    border-radius: 0.25rem;
    text-align: center;
    color: #012970;
    padding: 15px 0;
}

.iconslist i {
    margin: 0.25rem;
    font-size: 2.5rem;
}

.iconslist .label {
    font-family: var(--bs-font-monospace);
    display: inline-block;
    width: 100%;
    overflow: hidden;
    padding: 0.25rem;
    font-size: 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #666;
}

/*--------------------------------------------------------------
  # Profie Page
  --------------------------------------------------------------*/
.profile .profile-card img {
    max-width: 120px;
}

.profile .profile-card h2 {
    font-size: 24px;
    font-weight: 700;
    color: #2c384e;
    margin: 10px 0 0 0;
}

.profile .profile-card h3 {
    font-size: 18px;
}

.profile .profile-card .social-links a {
    font-size: 20px;
    display: inline-block;
    color: rgba(1, 41, 112, 0.5);
    line-height: 0;
    margin-right: 10px;
    transition: 0.3s;
}

.profile .profile-card .social-links a:hover {
    color: #012970;
}

.profile .profile-overview .row {
    margin-bottom: 20px;
    font-size: 15px;
}

.profile .profile-overview .card-title {
    color: #012970;
}

.profile .profile-overview .label {
    font-weight: 600;
    color: rgba(1, 41, 112, 0.6);
}

.profile .profile-edit label {
    font-weight: 600;
    color: rgba(1, 41, 112, 0.6);
}

.profile .profile-edit img {
    max-width: 120px;
}

/*--------------------------------------------------------------
  # F.A.Q Page
  --------------------------------------------------------------*/
.faq .basic h6 {
    font-size: 18px;
    font-weight: 600;
    color: #4154f1;
}

.faq .basic p {
    color: #6980aa;
}

/*--------------------------------------------------------------
  # Contact
  --------------------------------------------------------------*/
.contact .info-box {
    padding: 28px 30px;
}

.contact .info-box i {
    font-size: 38px;
    line-height: 0;
    color: #4154f1;
}

.contact .info-box h3 {
    font-size: 20px;
    color: #012970;
    font-weight: 700;
    margin: 20px 0 10px 0;
}

.contact .info-box p {
    padding: 0;
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 0;
}

.contact .php-email-form .error-message {
    display: none;
    color: #fff;
    background: #ed3c0d;
    text-align: left;
    padding: 15px;
    margin-bottom: 24px;
    font-weight: 600;
}

.contact .php-email-form .sent-message {
    display: none;
    color: #fff;
    background: #18d26e;
    text-align: center;
    padding: 15px;
    margin-bottom: 24px;
    font-weight: 600;
}

.contact .php-email-form .loading {
    display: none;
    background: #fff;
    text-align: center;
    padding: 15px;
    margin-bottom: 24px;
}

.contact .php-email-form .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid #18d26e;
    border-top-color: #eee;
    animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
    border-radius: 0;
    box-shadow: none;
    font-size: 14px;
    border-radius: 0;
}

.contact .php-email-form input:focus,
.contact .php-email-form textarea:focus {
    border-color: #4154f1;
}

.contact .php-email-form input {
    padding: 10px 15px;
}

.contact .php-email-form textarea {
    padding: 12px 15px;
}

.contact .php-email-form button[type="submit"] {
    background: #4154f1;
    border: 0;
    padding: 10px 30px;
    color: #fff;
    transition: 0.4s;
    border-radius: 4px;
}

.contact .php-email-form button[type="submit"]:hover {
    background: #5969f3;
}

@keyframes animate-loading {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/*--------------------------------------------------------------
  # Error 404
  --------------------------------------------------------------*/
.error-404 {
    padding: 30px;
}

.error-404 h1 {
    font-size: 180px;
    font-weight: 700;
    color: #4154f1;
    margin-bottom: 0;
    line-height: 150px;
}

.error-404 h2 {
    font-size: 24px;
    font-weight: 700;
    color: #012970;
    margin-bottom: 30px;
}

.error-404 .btn {
    background: #51678f;
    color: #fff;
    padding: 8px 30px;
}

.error-404 .btn:hover {
    background: #3e4f6f;
}

@media (min-width: 992px) {
    .error-404 img {
        max-width: 50%;
    }
}

/*--------------------------------------------------------------
  # Footer
  --------------------------------------------------------------*/
.footer {
    padding: 20px 0;
    text-align: center;
    font-size: 14px;
    transition: all 0.3s;
    border-top: 1px solid #cddfff;
    background-color: #ffffff;
    height: 50px;
    margin-left: -10px;
}

.footer .copyright {
    text-align: center;
    color: #012970;
}

.footer .credits {
    padding-top: 5px;
    text-align: center;
    font-size: 13px;
    color: #012970;
}

/* sidebar active */
.sidebarActive {
    background-color: #FFBE00;
    border-radius: 0px;


}

.nav-item {
    /* border: 1px solid red; */

}



/* Dropdown data  */
/* .react-select-container {
    width: 100%;
}

.react-select__control {
    min-height: 40px;
}

.react-select__option--is-selected {
    background-color: #007bff;
    color: white;
}

.react-select__option--is-focused {
    background-color: #007bff1a;
} */



.react-select__control {
    min-height: 50px;
}

.react-select__option--is-selected {
    background-color: #007bff;
    color: white;
}

.react-select__option--is-focused {
    background-color: #007bff1a;
}

.form-check-input:checked {
    background-color: #007bff;
    border-color: #007bff;
}

.form-check-input {
    width: 2rem;
    height: 1.5rem;
}


.react-select-container .react-select__control {
    min-height: 50px;
}

.custom-option {
    display: flex;
    align-items: center;
}

.custom-option input[type="checkbox"] {
    margin-right: 10px;
    accent-color: #007bff;
}


.section {
    border: 3px dotted #e0dfe6 !important;
    background-color: #f0eff7 !important;
}

.eedededde {
    margin-left: 300px !important;
    width: 1500px !important;
    margin-top: -20px !important;
    margin-bottom: 20px !important;

}


.custom-width {
    margin-left: 500px !important;
    margin-right: auto;
    width: 1500px !important;
    width: 100%;
    /* Adjust width to be responsive */
    max-width: 1600px;
    /* Maximum width */
    margin-top: -20px;
    margin-bottom: 20px;
}

@media (min-width: 992px) {
    .custom-width {
        margin-left: 500px;
        /* Margin-left for larger screens */
    }
}



/* tabs */
/* ProductCreate.css */

/* ProductForm.css */
.product-form {
    padding: 10px;
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.product-form__container {
    max-width: 800px;
    margin: 0 auto;
}

.form-group {
    margin-bottom: 20px;
}

.form-group__label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
    color: #333;
}

.form-group__input,
.form-group__ckeditor {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.form-group__input:focus,
.form-group__ckeditor:focus {
    border-color: #007bff;
    outline: none;
}

.form-group__error {
    color: #dc3545;
    font-size: 0.875rem;
}

.form-group__checkbox {
    display: flex;
    align-items: center;
}

.form-group__checkbox-input {
    margin-right: 10px;
}

.form-group__submit-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
}

.form-group__submit-button:hover {
    background-color: #0056b3;
}

.product-form__image-preview {
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.product-form__image-preview-img {
    max-width: 100px;
    max-height: 100px;
    border-radius: 4px;
}

.product-form__image-preview-button {
    background: none;
    border: none;
    color: #dc3545;
    cursor: pointer;
    font-size: 1.5rem;
    margin-left: 10px;
}


/* ProductCreate.css */

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
}

body {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: #f9f9f9;
}

::selection {
    background: rgba(187, 134, 252, 0.5);
    color: #fff;
}

.product-create__main {
    display: flex;
    height: calc(100vh - 60px);
}

.product-create__sidebar {
    width: 17%;
    background-color: transparent;
    color: white;
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: transparent !important;
}

.product-create__sidebar-header {
    padding: 20px;
    border-bottom: 1px solid #0056b3;
}

.product-create__title {
    margin: 0;
    font-size: 20px;
    font-weight: bold;
}

.product-create__tabs {
    flex-grow: 1;
    position: relative;
}

.product-create__tab {
    width: 100%;
    padding: 15px;
    text-align: left;
    background-color: tran;
    border: none;
    cursor: pointer;
    font-size: 16px;
    color: #0056b3 !important;
    transition: all 0.5s ease;
    background-color: transparent !important;
}

.product-create__tab--active,
.product-create__tab:hover {
    color: #ffffff;
}

.product-create__tab:disabled {
    background-color: #303a44;
    cursor: not-allowed;
    opacity: 0.5;
}

.product-create__slider {
    position: absolute;
    left: 0;
    top: 0;
    height: 60px;
    width: 100%;
    transition: all 0.5s ease;
    color: white !important;
    background-color: #70a3da5d !important;
    border-bottom: 1px solid #0056b3 !important;
}

.product-create__tab-content {
    flex-grow: 1;
    overflow-y: auto;
    background-color: #f9f9f9;
    width: 75% !important;
}

.product-create__tab-pane {
    display: none;
}

.product-create__tab-pane.active {
    display: block;
}


/* ________ */






/* Card form */

.form-control {}


.form-select {

    width: 450px !important;
}


/* Card */
.card-form {
    width: 1180px !important;
    height: 720px !important;
    margin-bottom: 30px;
    border: none;
    border-radius: 5px;
    box-shadow: 0px 0 30px rgba(1, 41, 112, 0.1);
    border: 2px solid rgb(209, 10, 10) !important;

}

.card-form-header,
.card-from-footer {
    border-color: #ebeef4;
    background-color: #fff;
    color: #798eb3;
    padding: 15px;
}

.card-form-title {
    padding: 20px 0 15px 0;
    font-size: 18px;
    font-weight: 500;
    color: #012970;
    font-family: "Poppins", sans-serif;
}

.card-form-title span {
    color: #899bbd;
    font-size: 14px;
    font-weight: 400;
}

.card-form-body {
    padding: 20px;
}

.card-form-img-overlay {
    background-color: rgba(255, 255, 255, 0.6);
}



/* new class */

.product-form {
    /* height: 720px; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.form-row {
    display: flex;
    justify-content: space-between;
}

.form-group {
    display: flex;
    flex-direction: column;
    margin: 5px;
    flex: 1;
}

.form-input {
    padding: 8px;
    margin-top: 5px;
    font-size: 14px;
}

.product-name,
.slug,
.description,
.specification,
.brand,
.category,
.discount,
.quantity,
.min-stock-warning,
.price,
.sku,
.thumbnail-image,
.tax,
.status,
.pre-order {
    width: 100%;
}

textarea.form-input {
    resize: vertical;
}

.form-group label {
    font-weight: bold;
}

.form-group:nth-child(2) {
    margin-left: 10px;
}

.form-group:nth-child(3),
.form-group:nth-child(4) {
    margin-left: 10px;
}

.form-group:last-child {
    margin-left: 10px;
}




.editor-container {
    height: 150px;
    /* Adjust height as needed */
}

.ck-editor__editable {
    min-height: 150px !important;
    /* Ensure the editor takes up full container height */
}


.form-label {
    color: #0056b3 !important;
}




/* +++++++++++++++++++++++++Login Page */

.admin-login-container {
    display: flex;
    width: 100vw;
    height: 100vh;
}

.admin-login-form {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f8f9fa;
    padding: 20px;
    min-width: 400px;
    /* Ensure minimum width */
}

.Paasword {
    border-radius: 0px !important;
}

.admin-logo {
    margin-left: 20px !important;
}

.admin-login-slider {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.slider-container {
    position: absolute;
    width: 80%;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    margin-bottom: 200px;
}

.slide {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: none;
}

.slide.active {
    display: block;
    animation: fade-in 1s ease-in-out;
}

@keyframes fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.dots {
    position: absolute;
    bottom: 20px;
    display: flex;
    gap: 10px;
}

.dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.7);
    cursor: pointer;
}

.dot.active {
    background-color: rgba(255, 255, 255, 1);
}

@media (max-width: 768px) {
    .admin-login-container {
        flex-direction: column;
    }

    .admin-login-form,
    .admin-login-slider {
        flex: none;
        width: 100%;
        height: 50%;
    }
}





.admin-login-slider {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-position: center;
    color: rgb(43, 40, 40);
    text-align: center;
    padding: 20px;
}

/* .slider-container {
    position: relative;
    width: 100%;
    height: auto;
    overflow: hidden;
} */

.dot {
    height: 10px;
    width: 10px;
    margin: 0 5px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
}

.dot.active {
    background-color: #717171;
}

.slider-text {
    margin-top: 800px;
}

.slider-text h2 {
    color: #454546;
    font-size: 23px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
}

.slider-text p {
    color: rgb(163, 159, 159);
    font-size: 13px;
    margin-top: 10px;
}

.text-muted {
    font-size: 13px;
}

p.text-end .text-decoration-none {
    text-decoration: none;
}

.googlebutton {
    border: 1px solid rgb(173, 171, 171) !important;
}

/* ....... */
.product-create__tabs {
    display: flex;
    flex-direction: column;
}

.product-create__sub-tabs {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    font-weight: 90px;
}

.product-create__sub-tab {
    background: transparent;
    color: #007bff;
    padding: 10px;
    cursor: pointer;
    margin-bottom: 5px;
}

.product-create__sub-tab--active {
    background: #088ce442;
    border-bottom: 1px solid rgb(44, 44, 231);

    color: white;
}

.product-create__tab-content {
    margin-top: 0px;
    height: fit-content;
}

.product-create__sub-tab-content {
    display: flex;
    flex-direction: column;
}

.product-create__sub-tab-pane {
    display: none;
}

.product-create__sub-tab-pane.active {
    display: block;
}


/* ::::::::: */
.ImageSection {
    width: 1050px;
}


/* .brand-edit-modal {
    border: 2px solid rgb(44, 112, 236);
    width: fit-content;
    height: fit-content;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10%;
    margin-bottom: 50%;
} */

/* Background blur effect */
.ReactModal__Overlay {
    background: rgba(230, 228, 228, 0.5) !important;
    /* Light black background with opacity */
    backdrop-filter: blur(8px);
    /* Apply blur effect */
    transition: opacity 0.3s ease;
    /* Smooth transition */
}



/* Modal styles */
.brand-edit-modal {
    margin-top: 5% !important;
    max-width: 500px;
    /* Adjust as needed */
    margin: auto;
    overflow: hidden;
    border: 2px solid #ffffff;
    padding: 10px;
    background-color: white;

}

/* Header styles */
.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px !important;
    color: white;
    background-color: #4d8ee2;
}

.close-button {
    border: none;
    background: transparent;
    cursor: pointer;
}

/* Body styles */
.modal-body {
    padding: 20px;
    display: flex;
    flex-direction: column;
    /* border: 1px solid red; */
}

.form-group {
    margin-bottom: 16px;
}

.image-upload-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.image-preview {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.image-delete-button {
    position: absolute;
    top: 8px;
    right: 8px;
    border: none;
    background: transparent;
    cursor: pointer;
}

.image-upload-placeholder {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}

input[type="file"] {
    display: none;
}

/* Footer styles */
.modal-footer {
    display: flex;
    justify-content: flex-end;
    padding: 16px;
    background-color: #f1f1f1;
}

.submit-button,
.cancel-button {
    margin-left: 8px;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
}

.submit-button {
    background-color: #007bff;
    color: white;
}

.cancel-button {
    background-color: #6c757d;
    color: white;
}

.cancel-button:hover {
    background-color: #db2923;
    color: white;
}






/* ::::::::::::::::::::::: */
/* section.css */
.main-form-container {
    padding: 10px;
}

.main-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.main-heading {
    font-size: 1.5rem;
    margin-bottom: 20px;
}

.section-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 15px;
    width: 100%;
    margin-bottom: 20px;
}

.section-item {
    position: relative;
    width: 150px;
    height: 40px;
}

.section-inner {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    backface-visibility: hidden;
    position: absolute;
}

.section-front,
.section-back {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    border-radius: 5px;
    text-align: center;
    transition: background-color 0.3s, transform 0.3s;
}

.section-front {
    z-index: 1;
}

.section-back {
    transform: rotateY(180deg);
    z-index: 0;
    background-color: #e0e0e0;
}

.icon-container {
    display: flex;
    height: 20px;
    gap: 30px;
}

.icon {
    font-size: 24px;
    cursor: pointer;
}

.add-section-btn {
    padding: 10px 20px;
    background-color: #df7b1f !important;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}


.section-item.selected .section-front {
    background-color: #007bff;
    color: white;
}

.plusBotton {
    background-color: transparent !important;
    color: #007bff;
    margin-top: -2px !important;

    display: flex;
    justify-content: center;
    align-items: center;
}

.plusBotton:hover {
    background-color: #007bff !important;
}


.card-body-image {
    background-color: white;
    border-radius: 20px;
    width: 900px;
    padding: 10px;
}

.previwImages {
    border: 2px dotted grey;
    border-radius: 15px;
    height: 250px;
    overflow: auto;
}


.SelectImages {
    border: 2px dotted grey;
    border-radius: 15px;
    padding: 10px;
}


.draggable-image {
    /* height: 10px !important;

    position: relative;
    margin: 5px; */

}

.draggable-image img {
    width: 70px !important;
    height: 70px !important;
}




/* *************** */
.editor-wrapper {
    position: relative;
    width: 480px;
    height: 150px;

}

.ck-editor__editable {
    width: 480px;
    height: 150px;
    overflow: auto;


}

.ck-editor__toolbar {
    position: sticky;
    top: 0;
    z-index: 10;
}


.custom-editor-content {
    width: 564px !important
}



.Main-Section-div {

    display: flex;
    justify-content: center !important;
    align-items: center;
}


.add-Section-btn {
    height: 40px;
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    border-radius: 0px;
}


.add-Section-btn:hover {
    color: white !important;
}


.Perveiw-Model-Class {}


.custom-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 800px;
}



.modal-transition-enter {
    opacity: 0;
    transform: translateY(-100%);
}

.modal-transition-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 300ms, transform 300ms;
}

.modal-transition-exit {
    opacity: 1;
    transform: translateY(0);
}

.modal-transition-exit-active {
    opacity: 0;
    transform: translateY(-100%);
    transition: opacity 300ms, transform 300ms;
}


.loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    color: #0056b3;
    z-index: 10;
}

.main {
    position: relative;
}



.selected-images-row {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;

}

.previwImages,
.preview-media-row {
    flex: 1;
    padding: 0 10px;
}

.preview-media-row {
    display: flex;
    align-items: center;
}