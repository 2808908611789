.main-form-container {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
  }
  
  select {
    width: 100%;
    padding: 5px;
    margin-bottom: 20px;
  }
  
  button {
    display: block;
    margin: 20px 0;
    padding: 10px 20px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  .section {
    margin-top: 20px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    padding: 20px;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }
.modal-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
   
}  
.main-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;

}
.main-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  height: 100%;
}
.main-label {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}
.main-dropdown {
  padding: 0.5rem;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  border: none;
  font-size: 1.5rem;
  width: 100%;
}
.add-section-btn {
  padding: 0.7rem 1.5rem;
  font-size: 1.5rem;
  border-radius: 0.5rem;
  border: none;
  background-color: #63E6BE;
  color: #fff;
  margin-bottom: 2rem;
  cursor: pointer;
}
.section {
  margin: 1rem 0;
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.section-title {
  font-size: 1.5rem;
  font-weight: bold;
}

.dropdown-container {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}
.dropdown {
  padding: 0.5rem;
  border-radius: 0.5rem;
  border: none;
  font-size: 1.5rem;
  width: 100%;
}
.save-button {
  padding: 0.7rem 1.5rem;
  font-size: 1.5rem;
  border-radius: 0.5rem;
  border: none;
  background-color: #63E6BE;
  color: #fff;
  cursor: pointer;
}

.Error-Msg{
  color: red;
}
.DeFault-Msg{
  color: gray;
}