.about-us-container {
    background-color: black;
    color: white;
    padding: 20px;
    border-radius: 10px;
    font-family: Arial, sans-serif;
    margin-left:315px;
    margin-top: 100px;

}

.about-us-content {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.about-us-content span {
    margin: 0 5px;
}
