@import url("https://fonts.googleapis.com/css2?family=Assistant:wght@200..800&display=swap");

/* ------------------------------------------------------------------------
                General Css Starts here
---------------------------------------------------------------------------*/
*,
html {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
}
body {
    font-family: "Assistant", serif !important;
    position: relative;
    overflow-x: hidden;
}
a {
    text-decoration: none;
}
.section_padding {
    padding-top: 30px !important;
    padding-bottom: 0px !important;
}
.section_padding_py {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
}

/* general css ends here */

/* NavBar CSS Start */

.main_header {
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 999;
}
.main_header nav {
    padding-left: 50px;
    padding-right: 50px;
}




.main_header .user-options > ul {
    margin: 0;
    padding: 0;
    gap: 40px;
}
.main_header .user-options > ul > li  i {
    font-size: 18px;
    color: #212529;
}
.main_header .user-options > ul > li > span {
    font-size: 12px;
    font-weight: bold;
}
.main_header .user-options > ul > li {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.main_header .submenu-heading {
    font-weight: bold;
    height: 30px;
    margin-bottom: 0;
    font-size: 14px;
}


.main_header .user-options .search_window
{
    position: absolute;
    left: -100%;
    bottom: 0;
    top: 0;
    margin: auto;
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 10px 20px;
    background: #fff;
    width: 100%;
    transition: left 0.1s ease-in-out;
    -webkit-transition: left 0.1s ease-in-out;
    -moz-transition: left 0.1s ease-in-out;
    -ms-transition: left 0.1s ease-in-out;
    -o-transition: left 0.1s ease-in-out;
}
.main_header .user-options .search_window.show
{
    left: 0;
}
.main_header .user-options .search_window input
{
    width: 100%;
    border: none;
    outline: none;
    vertical-align: middle;
}
.main_header .user-options .search_window a i
{
    font-size: 20px;
    color: #282c3f;
}

.main_header .menu-item-one .submenu-heading {
    color: #ee5f73;
}
.main_header .menu-item-two .submenu-heading {
    color: #fb56c1;
}
.main_header .menu-item-three .submenu-heading {
    color: #f26a10;
}
.main_header .menu-item-four .submenu-heading {
    color: #f2c210;
}
.main_header .menu-item-five .submenu-heading {
    color: #0db7af;
}
.main_header .menu-item-six .submenu-heading {
    color: #ff3f6c;
}

.main_header .menu-item-one > a:hover {
    border-bottom: 2px solid #ee5f73;
    transition: 0.1s;
}

.main_header .menu-item-two > a:hover {
    border-bottom: 2px solid #fb56c1;
    transition: 0.1s;
}

.main_header .menu-item-three > a:hover {
    border-bottom: 2px solid #f26a10;
    transition: 0.1s;
}

.main_header .menu-item-four > a:hover {
    border-bottom: 2px solid #f2c210;
    transition: 0.1s;
}

.main_header .menu-item-five > a:hover {
    border-bottom: 2px solid #0db7af;
    transition: 0.1s;
}

.main_header .menu-item-six > a:hover {
    border-bottom: 2px solid #ff3f6c;
    transition: 0.1s;
}

.main_header .search {
    background-color: #f5f5f6;
    display: flex;
    width: 500px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: start;
    padding-left: 20px;
    padding-right: 20px;
}
.main_header .search > input {
    width: 100%;
    padding: 10px;
    border: none;
    outline: none;
    background: transparent;
    font-size: 14px;
}
.main_header .nav-left-opt
{
    gap: 20px;
}
.main_header .nav-left-opt > li {
    color: #000;
    cursor: pointer;
    font-weight: bold;
    text-transform: capitalize;
    font-size: 16px;
}
.main_header .nav-left-opt > li > a {
    text-decoration: none;
    color: #000;
}

.main_header .menu-item {
    position: relative;
}

.main_header .menu-anchor {
    display: flex;
    align-items: center;
    transition: 0.1s;
    height: 70px;
}

.main_header .submenu {
    display: none;
    opacity: 0;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
    min-width: max-content;
    /* padding: 25px; */
    z-index: 999;
}

.main_header .menu_part {
    gap: 60px;
}
.main_header .menu_part .submenu
{
    padding: 0 10px;
}
.main_header .menu_part .submenu .sub_menu_par:nth-child(even)
{
    background-color: rgba(245, 245, 246, .4);
}
.main_header .menu_part .sub_menu_par
{
    display: flex;
    flex-direction: column;
    height: 450px;
    gap: 20px;
    min-width: 140px;
    padding:25px 15px 25px 15px;
}
.main_header .menu_part .sub_menu_par .sub_menu_par_sing
{
    border-bottom: 1px solid lightgrey;
    padding-bottom: 10px;
}
.main_header .menu_part .sub_menu_par .sub_menu_par_sing:last-child
{
    border-bottom: none;
    padding-bottom: 0;
}

.main_header .menu_part ul {
    font-size: 14px;
}

.main_header .submenu-item a {
    color: #000;
    font-weight: normal;
    text-decoration: none;
    display: flex;
    height: 24px;
}

.main_header .submenu-item a:hover {
    font-weight: bold;
    color: #000;
}
.main_header .menu-item.has-submenu {
    transition: 0.2s;
}
.main_header .menu-item.has-submenu:hover .submenu,
.main_header .menu-item.has-submenu.active .submenu {
    display: flex;
    opacity: 1;
}

@media (width <= 575.98px) {
    .main_header nav {
        padding: 0.5rem 0;
    }
    .main_header nav #main_side_bar
    {
        background: transparent;
        border: none;
        padding-right: 10px;
    }
    .main_header nav #main_side_bar i
    {
        font-size: 20px;
    }
    .main_header .user-options > ul
    {
        gap: 20px;
    }
    .main_header .user-options > ul > li > i
    {
        font-size: 22px;
    }
}

/* NavBar CSS End */

/* main sidebar starts here */
.main_side_bar_bg {
    position: fixed;
    left: -100%;
    top: 0;
    z-index: 1001;
    width: 70%;
    height: 100%;
    overflow: hidden;
    transition: left 0.5s ease-in-out;
    -webkit-transition: left 0.5s ease-in-out;
    -moz-transition: left 0.5s ease-in-out;
    -ms-transition: left 0.5s ease-in-out;
    -o-transition: left 0.5s ease-in-out;
}
.main_side_bar_bg.show {
    left: 0;
}
.main_side_bar_overlay {
    content: "";
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    opacity: 1;
    transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
    z-index: 1000;
}
.main_side_bar_bg.show + .main_side_bar_overlay {
    display: block;
}
.main_side_bar_bg .main_side_bar {
    position: relative;
    height: 100%;
    background: #fff;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    will-change: transform;
    z-index: 120;
    pointer-events: auto;
}
.main_side_bar_bg .main_side_bar .m_s_top {
    border-bottom: 8px solid #fff2df;
}
.main_side_bar_bg .main_side_bar .m_s_middle .m_s_1_p {
    padding: 14px 20px 14px 20px;
}
.main_side_bar_bg .main_side_bar .m_s_middle ul {
    list-style: none;
    padding: 0;
    padding-left: 20px;
    margin: 0;
}
.main_side_bar_bg .main_side_bar .m_s_middle ul li {
    border: none;
}
.main_side_bar_bg .main_side_bar .m_s_middle ul li,
.main_side_bar_bg .main_side_bar .m_s_middle ul li h6 {
    font-size: 14px;
    position: relative;
    margin: 0;
}
.main_side_bar_bg .main_side_bar .m_s_middle ul li h6 {
    padding: 14px 0px;
}
.main_side_bar_bg .main_side_bar .m_s_middle .m_s_1_p > li > h6 {
    font-weight: 700;
}
.main_side_bar_bg .main_side_bar .m_s_middle ul li.has_sub > h6::after {
    position: absolute;
    content: "\f078";
    font-family: FontAwesome;
    right: 0px;
    font-size: 12px;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.main_side_bar_bg .main_side_bar .m_s_middle ul li.has_sub > h6.show::after {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
}
.main_side_bar_bg .main_side_bar .m_s_middle ul li li {
    padding-right: 0;
}

.main_side_bar_bg .main_side_bar .m_s_middle ul li.has_sub ul {
    display: none;
}

.main_side_bar_bg .main_side_bar .m_s_bottom
{
    border-top: 1px solid lightgrey;
}
.main_side_bar_bg .main_side_bar .m_s_bottom ul
{
    list-style: none;
    padding: 0;
    margin: 0;
}
.main_side_bar_bg .main_side_bar .m_s_bottom ul li a
{
    font-size: 14px;
    border: none;
    padding: 14px 20px;
    display: inline-block;
    color: #282c3f;
}
.main_side_bar_bg .main_side_bar .m_s_bottom ul li a .badge
{
    margin-left: 5px;
    padding: 2px 6px;
    border-radius: 7px;
    border: .5px solid #ff3f6c;
    font-family: Assistant;
    font-size: 10px;
    font-weight: 600;
    color: #ff3f6c;
    vertical-align: middle;
}
.main_side_bar_bg .main_side_bar .m_s_btm_img img
{
    width: 100%;
}


/* footer css starts here */
footer .footer_links h6 {
    font-weight: bold;
    text-transform: capitalize;
}
footer .footer_links ul {
    list-style: none;
    padding-left: 0;
}
footer .list-unstyled {
    padding-left: 15px;
    margin-top: 16px;
}
footer .list-unstyled li {
    margin-bottom: 16px;
}
footer .footer_links h6.social_head {
    margin-top: 20px;
}
footer .footer_links a {
    color: #000;
    text-decoration: none;
    font-size: 16px;
}
footer .footer_links .social_icons a i {
    font-size: 22px;
}
footer p,
footer address {
    margin-bottom: 0;
}

footer .footer_title {
    font-weight: bold;
    text-transform: capitalize;
    margin-top: 20px;
}
footer .popular_search a {
    color: #000;
    text-decoration: none;
    font-size: 16px;
    position: relative;
    padding-right: 15px;
}

footer .popular_search a::after {
    position: absolute;
    content: "";
    border: 1px solid grey;
    height: 15px;
    bottom: 0;
    right: 5px;
}

@media(width <= 575.98px)
{

    footer .responsive_foot p
    {
        position: relative;
        padding: 10px 0; 
    }
    footer .responsive_foot p::after
    {
        position: absolute;
        content: "\f078";
        font-family: FontAwesome;
        right: 0px;
        font-size: 12px;
        transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
    }
    footer .responsive_foot p.show::after
    {
        transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
    }

    footer .responsive_foot_content
    {
        display: none;
    }
}


/* footer css ends here */

/* notification starts here */
.notification_btn {
    display: inline-flex;
    position: fixed;
    bottom: 25px;
    right: 25px;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background: blue;
    font-size: 24px;
    align-items: center;
    place-content: center;
    z-index: 999;
}
.notification_btn i {
    color: #fff;
    -webkit-animation: ring 4s 0.7s ease-in-out infinite;
    -webkit-transform-origin: 50% 4px;
    -moz-animation: ring 4s 0.7s ease-in-out infinite;
    -moz-transform-origin: 50% 4px;
    animation: ring 4s 0.7s ease-in-out infinite;
    transform-origin: 50% 4px;
}

@-webkit-keyframes ring {
    0% {
        -webkit-transform: rotateZ(0);
    }
    1% {
        -webkit-transform: rotateZ(30deg);
    }
    3% {
        -webkit-transform: rotateZ(-28deg);
    }
    5% {
        -webkit-transform: rotateZ(34deg);
    }
    7% {
        -webkit-transform: rotateZ(-32deg);
    }
    9% {
        -webkit-transform: rotateZ(30deg);
    }
    11% {
        -webkit-transform: rotateZ(-28deg);
    }
    13% {
        -webkit-transform: rotateZ(26deg);
    }
    15% {
        -webkit-transform: rotateZ(-24deg);
    }
    17% {
        -webkit-transform: rotateZ(22deg);
    }
    19% {
        -webkit-transform: rotateZ(-20deg);
    }
    21% {
        -webkit-transform: rotateZ(18deg);
    }
    23% {
        -webkit-transform: rotateZ(-16deg);
    }
    25% {
        -webkit-transform: rotateZ(14deg);
    }
    27% {
        -webkit-transform: rotateZ(-12deg);
    }
    29% {
        -webkit-transform: rotateZ(10deg);
    }
    31% {
        -webkit-transform: rotateZ(-8deg);
    }
    33% {
        -webkit-transform: rotateZ(6deg);
    }
    35% {
        -webkit-transform: rotateZ(-4deg);
    }
    37% {
        -webkit-transform: rotateZ(2deg);
    }
    39% {
        -webkit-transform: rotateZ(-1deg);
    }
    41% {
        -webkit-transform: rotateZ(1deg);
    }

    43% {
        -webkit-transform: rotateZ(0);
    }
    100% {
        -webkit-transform: rotateZ(0);
    }
}

@-moz-keyframes ring {
    0% {
        -moz-transform: rotate(0);
    }
    1% {
        -moz-transform: rotate(30deg);
    }
    3% {
        -moz-transform: rotate(-28deg);
    }
    5% {
        -moz-transform: rotate(34deg);
    }
    7% {
        -moz-transform: rotate(-32deg);
    }
    9% {
        -moz-transform: rotate(30deg);
    }
    11% {
        -moz-transform: rotate(-28deg);
    }
    13% {
        -moz-transform: rotate(26deg);
    }
    15% {
        -moz-transform: rotate(-24deg);
    }
    17% {
        -moz-transform: rotate(22deg);
    }
    19% {
        -moz-transform: rotate(-20deg);
    }
    21% {
        -moz-transform: rotate(18deg);
    }
    23% {
        -moz-transform: rotate(-16deg);
    }
    25% {
        -moz-transform: rotate(14deg);
    }
    27% {
        -moz-transform: rotate(-12deg);
    }
    29% {
        -moz-transform: rotate(10deg);
    }
    31% {
        -moz-transform: rotate(-8deg);
    }
    33% {
        -moz-transform: rotate(6deg);
    }
    35% {
        -moz-transform: rotate(-4deg);
    }
    37% {
        -moz-transform: rotate(2deg);
    }
    39% {
        -moz-transform: rotate(-1deg);
    }
    41% {
        -moz-transform: rotate(1deg);
    }

    43% {
        -moz-transform: rotate(0);
    }
    100% {
        -moz-transform: rotate(0);
    }
}

@keyframes ring {
    0% {
        transform: rotate(0);
    }
    1% {
        transform: rotate(30deg);
    }
    3% {
        transform: rotate(-28deg);
    }
    5% {
        transform: rotate(34deg);
    }
    7% {
        transform: rotate(-32deg);
    }
    9% {
        transform: rotate(30deg);
    }
    11% {
        transform: rotate(-28deg);
    }
    13% {
        transform: rotate(26deg);
    }
    15% {
        transform: rotate(-24deg);
    }
    17% {
        transform: rotate(22deg);
    }
    19% {
        transform: rotate(-20deg);
    }
    21% {
        transform: rotate(18deg);
    }
    23% {
        transform: rotate(-16deg);
    }
    25% {
        transform: rotate(14deg);
    }
    27% {
        transform: rotate(-12deg);
    }
    29% {
        transform: rotate(10deg);
    }
    31% {
        transform: rotate(-8deg);
    }
    33% {
        transform: rotate(6deg);
    }
    35% {
        transform: rotate(-4deg);
    }
    37% {
        transform: rotate(2deg);
    }
    39% {
        transform: rotate(-1deg);
    }
    41% {
        transform: rotate(1deg);
    }

    43% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(0);
    }
}

.notification_popup.popup {
    position: fixed;
    bottom: 77px;
    right: 40px;
    width: 300px;
    background: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    transform: scale(0);
    transform-origin: bottom right;
    opacity: 0;
    transition: transform 0.3s ease-out, opacity 0.3s ease-out;
    z-index: 999;
    border-radius: 10px;
}
.notification_popup.popup.show {
    transform: scale(1);
    opacity: 1;
}
.notification_popup .popup-header {
    background: #007bff;
    color: #fff;
    padding: 8px 10px;
    border-bottom: 1px solid #ccc;
    position: relative;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    text-align: center;
}
.notification_popup .popup-header h3 {
    margin: 0;
    font-size: 20px;
    font-weight: 600;
}
.notification_popup .popup-header p {
    margin: 0;
}
.notification_popup .popup-header p a {
    color: #fff;
}
.notification_popup .popup-header .close-btn {
    position: absolute;
    top: 15px;
    right: 20px;
    cursor: pointer;
    font-size: 24px;
}
.notification_popup .popup-content {
    padding: 10px;
    height: 350px;
    overflow-x: auto;
}
.notification_popup .popup-content::-webkit-scrollbar {
    width: 0;
}
.notification_popup .offer {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
.notification_popup .offer img {
    margin-right: 10px;
}
.notification_popup .offer-details p {
    margin: 0;
    font-weight: bold;
}
.notification_popup .offer-details span {
    font-size: 12px;
    color: #888;
}
/* notification ends here */

/* offer popup starts here */
.offer_popup {
    position: fixed;
    z-index: 999;
    background: #fff;
    right: 0;
    bottom: 100px;
    display: flex;
    width: 550px;
    right: -506px;
    transition: right 0.5s ease-in-out;
    -webkit-transition: right 0.5s ease-in-out;
    -moz-transition: right 0.5s ease-in-out;
    -ms-transition: right 0.5s ease-in-out;
    -o-transition: right 0.5s ease-in-out;
}
.offer_popup.show {
    right: 0;
}
.offer_popup .offer_btn {
    background-color: #535766;
    cursor: pointer;
}
.offer_popup .offer_btn h3 {
    writing-mode: vertical-rl;
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
    width: 36px;
    padding: 7px;
    height: 200px;
    vertical-align: middle;
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    -webkit-margin-after: 0;
    margin-block-end: 0;
    -webkit-margin-before: 0;
    margin-block-start: 0;
    color: #fcfcfc;
    display: inline-block;
}
.offer_popup .offer_btn .arrow {
    border-left: 12px solid #fff;
    width: 0;
    height: 0;
    margin: 24px 16px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
}
.offer_popup .offer_content {
    background-image: linear-gradient(270deg, #fef9e5, #fde3f3);
}
.offer_popup .offer_content .o_c_top h6 {
    font-weight: 700;
    font-size: 12px;
    margin: 0;
}
.offer_popup .offer_content .o_c_top h2 {
    font-size: 48px;
    font-weight: 700;
    margin: 0;
}
.offer_popup .offer_content .o_c_top .bottom h6 {
    font-weight: 700;
    font-size: 15px;
    margin-top: 8px;
    width: max-content;
}
.offer_popup .offer_content .o_c_top .bottom h6 span {
    margin-left: 12px;
    font-size: 18px;
    font-weight: 700;
    cursor: text;
    -webkit-user-select: all;
    -moz-user-select: all;
    -ms-user-select: all;
    user-select: all;
}
.offer_popup .offer_content .o_c_top .bottom p {
    font-size: 12px;
    margin-top: 4px;
}
.offer_popup .offer_content .o_c_top .bottom a {
    width: 172px;
    height: 48px;
    border-radius: 4px;
    background-color: #ff3f6c;
    color: #fff;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 48px;
}

.offer_popup .offer_content .o_c_bottom h6 {
    width: fit-content;
}
.offer_popup .offer_content .o_c_bottom .o_c_b_p {
    display: flex;
    gap: 10px;
    justify-content: space-evenly;
    border-top: 1px solid #d3d3d3;
    padding: 10px;
    align-items: center;
}
.offer_popup .offer_content .o_c_bottom .o_c_b_p h6 {
    font-size: 13px;
    font-weight: 700;
    margin-left: 12px;
    margin-bottom: 0;
}
.offer_popup .offer_content .o_c_bottom .o_c_b_p h6 i {
    padding-right: 8px;
    color: green;
    font-size: 16px;
}
/* offer popup ends here */


/* ------------------------------------------------------------------------
                        General Css Ends here
---------------------------------------------------------------------------*/

/* ------------------------------------------------------------------------
                        Home Page Css Starts here
---------------------------------------------------------------------------*/

.top_banner img,
.section_six img {
    width: 100%;
}
.hero_banner .owl-nav
{
    position: absolute;
    top: 0;
    bottom: 0;
    height: fit-content;
    margin: auto;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
}
.hero_banner .owl-nav button 
{
    width: 50px;
    height: 50px;
    background: rgb(0, 0, 0, 0.7) !important;
    border-radius: 100%;
    color: #fff !important;
    font-size: 40px !important;
    display: flex;
    place-content: center;
    line-height: 40px !important;
}
.hero_banner .owl-dots
{
    display: none;
}
.section_five img {
    width: 100%;
}
.hero_banner .slick-slide img{
    width: 100%;
}
@media(width <= 575.98px)
{
    .section_five img
    {
        width: fit-content;
    }
}

/* ------------------------------------------------------------------------
                        Home Page Css Ends here
---------------------------------------------------------------------------*/

/* ------------------------------------------------------------------------
                        Home Page Css Starts here
---------------------------------------------------------------------------*/

/* ------------------------------------------------------------------------
                        Home Page Css Ends here
---------------------------------------------------------------------------*/
